
.heading{
    color: white;
    text-align: center;  
    font-size: 4rem;
}

.subheader{
    text-align: center;
}

.aboutSection{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
}


.about{
    width: 50%;
    padding: 0 5rem;
}

.emphasize{
    color: #ffffff;
}

.skills{
    display: flex;
    flex-wrap: wrap;
}

.chip{
    padding: 0.5rem 2rem;
    margin-right: 2rem;
    border-radius: 1.5rem;
    background-color: #635d5d;
}

.contactDetails{
    text-transform: uppercase;
    font-size: 2.5rem;
    color: white;
    text-align: center;
}

.email{
    text-decoration: underline;
    cursor: pointer;
}


@media screen and (max-width: 821px){
   .aboutSection{
       flex-direction: column;
       width: 90%;
   }

   .heading{
       text-transform: uppercase;
   }

   .about{
       width: 100%;
       padding: 0 1rem;
   }

   h3{
       margin-bottom: 2rem;
       text-align: center;
   }

   .skills{
       justify-content: center;
   }

   /* .bio{
       text-align: center;
   } */
}