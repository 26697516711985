* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

body{
    background-image: radial-gradient(circle, #73aa8a 18%, #303030 71%);
    min-height: 100vh;
    width: 100%;
    font-family: 'Poppins',sans-serif;
}

h1,h2{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
}