.projectItem{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.projectImage{
    width: 600px;
}

.projectDetails{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 2rem;
}

.projectDetails p{
    font-style: italic;
}

h2{
    color: black;
}

@media  screen and (max-width: 821px) {
    .projectItem{
        flex-direction: column;
        margin-bottom: 5rem;
    }

    .projectImage{
        width: 100%;
    }

    .projectDetails{
        margin-top: 4rem;
    }

    .projectDetails p{
        font-style: italic;
    }
    
}