header{
    height: auto;
}

.homePage{
    padding: 0 2rem;
    width: 80%;
    margin: 0 auto;
    padding-top: 6rem;
    min-height: 100vh;
    height: auto;
}


.nav{
    display: flex;
    justify-content: space-between;
    
}

.navItems{
    display: flex;
}


.socialIcons{
    display: flex;
}

.description{
    display: flex;
    position: relative;
    /* align-items: center; */
    /* margin-top: 6rem; */
    /* margin-bottom: 2rem; */
    height: 6rem;
    /* padding-left: 1rem; */
}

.companyName::before{
    content: '';
    display: block;
    width: 250px;
    font-size: 6rem;
}

.companyName{
    text-transform: uppercase;
    font-size: 6rem;
    margin-bottom: 0;
    /* margin-right: 2rem; */
}

.subTitle{
    padding-top: 1rem;
}

/* @media screen and (max-width:820px)  {
    .homePage{
        width: 100%;
        padding: 0;
        height: 100vh;
    }
    
} */

@media  screen and (max-width: 821px) {
    header{
        height: auto;
    }
    .homePage{
        width: 100%;
        padding: 0;
        min-height: 100vh;
        height: 100%;
    }
    .description{
        flex-direction: column;
        margin-top: 4rem;
        margin-bottom: 6rem;
        text-align: center;
    }
    .companyName{
        font-size: 4rem;
    }
    .subTitle{
        font-size: 1.75rem;
    }
    .navItems{
        flex-direction: column;
    }
    .socialIcons{
        display: block;
    }
}

