/* .slide{
    overflow-y: hidden;
} */
.description{
    width: 40%;
    text-align: center;
    padding: 1rem;
    /* margin-top: 2rem; */
    /* bottom: 1rem; */
}

.card{
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 0rem 1rem;
    padding-bottom: 3rem;
    color: white;
}

.cardTitle{
    text-align: center;
    text-transform: uppercase;
}

.cardDescription{
    padding: 0 2rem;
}

.image{
    width: 100%;
    height: 150px;
    object-fit: cover;
}



 @media screen and (min-width:376px){
/*     .slide{
        height: 50%;
    } */
    .image{
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
 }

 @media screen and (min-width:767px){
     .image{
         min-height: 55vh;
     }
 }
 

@media screen and (min-width:1024px){
    .slide{
        position: relative;
        width: auto;
        height: 100%;
        background-color: black;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    
    .image{
        min-height: 100%;
        display: block;
        /* object-fit: contain; */
    }
    
    
    .card{
        position: absolute;
        /* min-width: 700px; */
        min-width: 300px;
        width: 30%;
        /* height: 20%; */
        /* height: 220px; */
        background-color: #000;
        border-radius: 1.5rem;
        bottom: 11.5%;
        left: 7.5%;
        /* margin-left: 6rem;
        margin-bottom: 3rem; */
        padding: 2rem 1rem 2rem 1rem;
        text-align: left;
        opacity: .85;
    
    }
    
    .cardTitle{
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #fff;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        text-align: center;
        opacity: 1;
        letter-spacing: 1rem;
    
    }
    
    .cardDescription{
        opacity: 1;
        color: #fff;    
    }
}
