    
    .buttonClass{
        width: 100%;
        text-align: center;
        margin-top: 2rem;
    }
    .submitButton{
        background-color: black;
        color: white
    }

    .address{
        text-align: center;
    }

    .form{
        background-color: rgb(80, 80, 80);
        padding: 0 2rem;
        padding-top: 2rem;
        border-radius: 0.5rem;
        
    }

    .email{
        text-decoration: underline;
        cursor: pointer;
    }
    
