.section{
    width: 60%;
    height: 100%;
    margin: 0 auto;
}

.section h3{
    color: black;
}


@media screen and (max-width: 821px){
    .section{
        width: 90%;
    }

    .section h3{
        text-align: center;
    }
    
}