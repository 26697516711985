.portfolio{
    width: 100%;
}

.header{
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-size: 4rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
}

.divder{
    margin: 0 15rem;
    margin-top: 4rem;
    border: 1px solid rgb(223, 223, 223);
}

@media screen and (max-width: 821px) {
    .divder{
        margin: 0 8rem;
    }
}



