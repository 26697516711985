.projectDetails{
    background-color: white;
    height: 100%;
    
}

.banner{
    padding: 6rem 0;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner h2{
    font-size: 4rem;
}

.banner p {
    max-width: 700px;
    text-align: center;
    margin-top: 2rem;
    font-style: italic;
}

.break{
    width: 60%;
    margin: 0 auto;
    margin-bottom: 3rem;
}

.section{
    height: 100%;
    padding-bottom: 2rem;
}

.tools{
    width: 60%;
    margin: 0 auto;
}

.tools h4{
    color: black;
    text-transform: uppercase;
}

.toolDisplay{
    display: flex;
    /* width: 60%; */
    padding-bottom: 2rem;
}

.tool{
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    background-color: lightgray;
    color: black;
    border-radius: 1rem;
}

@media screen and (max-width: 821px){
    .break{
        width: 100%;
    }
    
    .tools{
        width: 90%;
    }

    .toolDisplay{
        flex-wrap: wrap;
        width: 100%;
    }

    .tool{
        margin-bottom: 1rem;
    }

    .banner{
        height: auto;
        padding-top: 5rem;
    }

    .banner p {
        width: 90%;
        font-style: italic;
    }
}



